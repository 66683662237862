import React from 'react'

import Layout from 'components/Layout'

const NoPage = () => (
  <Layout>
    <oma-grid-row>
      <section className='section'>
        <h2>Sidan kunde inte hittas</h2>
        <p>
          Ajdå, sidan kunde inte hittas. Kontakta oss gärna om du tror att
          någonting är fel. Titta gärna runt på någon av de andra sidorna här på
          webbplatsen.
        </p>
      </section>
    </oma-grid-row>
  </Layout>
)

export default NoPage
